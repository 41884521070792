.image-routing{
    width: 100%;
}

.buttons-wrapper{
    padding-top: 5rem;
}

.landing-image-wrapper{
    background-image: url('../../images/building.webp');
    background-size: 100% 100%;
    width: 100%;
    padding:0px
}

.first-landing-button{
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 58%, rgba(255,255,255,0) 100%); 
    color:black;
    font-size: 1.5rem;
}

.second-landing-button{
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 58%, rgba(255,255,255,0) 100%); 
    color:black;
    font-size: 1.5rem;
}

.landing-title{
    font-size: 84pt;
    margin-top: 10rem;
    width: 100%;
    color:white;
}

.landing-subtitle{
    margin-top: 5rem;
    width: 100%;
    color:white;
}

.landing-banner-wrapper{
    height:110px;
    position:absolute;
    top:0px;
    left:0px;
    max-width: 550px;
    width:100vw;
}

.landing-banner-image{
    height:110px;
    max-width: 550px;
    width:100vw;
}

@media only screen and (max-width: 1430px) {
    .landing-title{
        font-size: 74pt;
    }
}

@media only screen and (max-width: 1230px) {
    .landing-title{
        font-size: 64pt;
    }
}

@media only screen and (max-width: 1030px) {
    .landing-title{
        font-size: 54pt;
    }
    .first-landing-button{
        font-size: 1.3rem;
    }

    .second-landing-button{
        font-size: 1.3rem;
    }
}
@media only screen and (max-width: 750px) {
    .landing-title{
        margin-top: 8rem;
        font-size: 40pt;
    }

    .landing-subtitle{
        font-size: 18pt;
    }

    .first-landing-button{
        font-size: 1rem;
    }

    .second-landing-button{
        font-size: 1rem;
    }

    .landing-banner-wrapper{
        height:80px;
    }
    
    .landing-banner-image{
        height:80px;
    }
}

@media only screen and (max-width: 400px) {
    .landing-title{
        
        font-size: 30pt;
    }

    .landing-subtitle{
        font-size: 15pt;
    }
}
