ol {
    list-style-type: none;
}

li{
    color: white;
}

.program{
    color: white;
}

.program:hover{
    color: #F3D250;
}

.footer{
    font-size: 0.85rem;
}