.single-contact-wrapper h3{
    font-weight: bold;
    padding-bottom: 2rem;
    margin: auto;
    text-align: center;
}

.single-contact-wrapper h4{
    padding-bottom: 2rem;
    margin: auto;
    text-align: center;
}

.single-contact-wrapper a{
    padding-left: 2rem;
    padding-right: 2rem
}

.contacts-wrapper{
    background-color: rgba(256, 256, 256, 0.6);
    margin-bottom: 1rem;
}