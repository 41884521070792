body {
  background-color: white !important;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('images/background.webp');
}
html{
  height:100%;
  scroll-behavior: smooth;
}
div#root{
  height:100vh;
  display: flex;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.div{
  margin-left: 20%;
  margin-right: 20%;
}

h1{
  text-align: center;
}
h2{
  text-align: center;
}

.news-wrapper{
  margin:auto;
  width: 70%;
  background-color: rgba(256, 256, 256, 0.6);
  margin-bottom: 1rem;
}

.tab-col{
  background-color: rgba(256, 256, 256, 0.6);
}

.events-general{
  margin: 0px 15%;
  background-color: rgba(256, 256, 256, 0.6);
}

