.nav-pills .nav-link{
    background-color:  #90CCF4;
    border-right: 5px solid #90CCF4;
    color: black;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}

.nav-pills .nav-link.active.info-tab{
    background-color: #22577E;
    border-right: 5px solid #22577E;
    color: white;
    cursor: pointer;
}

.general-info-wrapper{
    min-height: 470px;
}

.tab-col{
    margin: auto;
}

.nav-pills .nav-link.info-tab-row{
    background-color: rgba(0,0,0,0);
    border-right: none;
    border-bottom: 5px solid #90CCF4;
    color: black;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
}

.nav-pills .nav-link.active.info-tab-row{
    border-right: none;
    background-color: rgba(0,0,0,0);
    border-bottom: 5px solid #22577E;
    color: #22577E;
    cursor: pointer;
}

.flex-row{
    margin-left: auto;
}

.tab-content-image{
    width: 80%;
    max-height: 65vh;
}